import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmComponent } from '../../utils/confirm/confirm.component';
import { PlottedActivities, PlottedDoctors } from 'src/app/model/event';
import { Activity } from 'src/app/model/activity'
import { Doctor } from 'src/app/model/doctor';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
  	private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  public openSnackBar(message: string, action?: string, duration: number = 3000): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
		  duration: duration
		});
  }

  public openDialog(component, config: MatDialogConfig = { width: "600px", autoFocus: false }): MatDialogRef<any> {
    let dialogRef: MatDialogRef<any>;
    dialogRef = this.dialog.open(component, config);
    return dialogRef;
  }

  public confirm(config: { title?: string, message?: string, translateParams?: any, hasCancel?: boolean, button?:string, color?: string, extraButton?: string, extraButtonFn?: () => void  }): Observable<boolean> {

    let dialogRef: MatDialogRef<ConfirmComponent>;

    dialogRef = this.dialog.open(ConfirmComponent, {
      data: config
    });
    return dialogRef.afterClosed();
  }

  public openDialogWithData(component, config: { title?: string, doctors?:PlottedDoctors[], activities?:PlottedActivities[], activityArray: Activity[], doctorArray:Doctor[] }): MatDialogRef<any> {

    let dialogRef: MatDialogRef<any>;

    dialogRef = this.dialog.open(component, {
      data: config
    });
    return dialogRef;
  }  

}
