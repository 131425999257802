import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { S3 } from 'aws-sdk';
import { saveAs } from 'file-saver';
import { DATETIME_FORMAT_FILE_PREFIX_S3 } from '../../utils/constant';
import * as moment from 'moment';
import * as _ from 'lodash';
import { HelperService } from '../helper/helper.service';
import { environment } from '../../../environments/environment';
import { bindNodeCallback, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  constructor(
    private authService: AuthService,
    private helper: HelperService
  ) { }

  public download({ bucketName, key, filename }: {bucketName: string, key: string, filename?: string}, callback: (err?, data?: any) => void){
    this.authService.checkAWSCredentials( (err) => {
      if(err){
        return callback(err);
      }

      let bucket = new S3();

      let params = {
        Bucket: bucketName,
        Key: key,
      };

      bucket.getObject(params, (err, data) => {
        if(err){
          return callback(err);
        }
        saveAs(new Blob([data.Body as any], { type: data.ContentType }), filename);
        callback(null, data);
      });
    });
  }

  public upload(
      { bucketName, file, options }: {bucketName: string, file: File, options?: S3.ManagedUpload.ManagedUploadOptions}, 
      callback: (err?, s3Key?: any) => void
    ){
    this.authService.checkAWSCredentials( (err) => {
      if(err){
        return callback(err);
      }

      let bucket = new S3();

      let extraPrefix = this.helper.randomString(6);
      let kebabFilename = _.kebabCase(file.name);
      let now = moment().format(DATETIME_FORMAT_FILE_PREFIX_S3);

      let key = `${extraPrefix}-${now}-${kebabFilename}`;

      let params = {
        Bucket: bucketName,
        Key: key, 
        Body: file,
        ContentType: file.type
      };

      bucket.upload(params, options, (err) => {
        if(err){
          return callback(err);
        }
        callback(null, key);
      });
    });
  }

  public uploadV2(
      { folderName, file, options }: {folderName: string, file: File, options?: S3.ManagedUpload.ManagedUploadOptions}
    )
  {
    return new Observable((subsriber) => {
      this.authService.checkAWSCredentials( (err) => {
        if(err){
          subsriber.error(err);
          subsriber.complete()
        }
  
        let bucket = new S3({ region: environment.region });
  
        let now = moment().format(DATETIME_FORMAT_FILE_PREFIX_S3);
  
        let key = `${file.name}`;
        let bucketName = folderName ? (environment.storage.AWSS3.bucket + '/' + folderName) : environment.storage.AWSS3.bucket;
  
        let params = {
          Bucket: bucketName,
          Key: key, 
          Body: file,
          ContentType: file.type,
          ACL: 'bucket-owner-full-control' 
        };  

        bucket.upload(params, options, (err) => {
          if(err){
            subsriber.error(err);
            subsriber.complete()
          }
          subsriber.next(key);
          subsriber.complete()
        });
      });
    })
  }

  public uploadBulkV2(
    { folderName, files, options }: { folderName: string, files: File[], options?: S3.ManagedUpload.ManagedUploadOptions }
  ) {
    return new Observable((subscriber) => {
      this.authService.checkAWSCredentials((err) => {
        if (err) {
          subscriber.error(err);
          subscriber.complete();
        }
  
        const bucket = new S3({ region: environment.region });
        const bucketName = folderName
          ? `${environment.storage.AWSS3.bucket}/${folderName}`
          : environment.storage.AWSS3.bucket;
  
        const uploadPromises = files.map((file) => {
          const key = `${file.name}`;; 
          const params = {
            Bucket: bucketName,
            Key: key,
            Body: file,
            ContentType: file.type,
            ACL: 'bucket-owner-full-control',
          };
  
          return bucket.upload(params, options).promise();
        });
  
        Promise.all(uploadPromises)
          .then((results) => {
            subscriber.next(results.map((res) => res.Key)); 
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.error(error);
            subscriber.complete();
          });
      });
    });
  }
  

  public uploadV3(
    { folderName, file, options }: {folderName: string, file: File, options?: S3.ManagedUpload.ManagedUploadOptions}
  )
{
  return new Observable((subsriber) => {
    this.authService.checkAWSCredentials( (err) => {
      if(err){
        subsriber.error(err);
        subsriber.complete()
      }

      let bucket = new S3({ region: environment.region });

      let now = moment().format(DATETIME_FORMAT_FILE_PREFIX_S3);

      let key = `${file.name}`;
      let bucketName = folderName ? (folderName) : environment.storage.AWSS3.bucket;

      let params = {
        Bucket: bucketName,
        Key: key, 
        Body: file,
        ContentType: file.type,
        ACL: 'bucket-owner-full-control' 
      };  

      console.log(params)
      bucket.upload(params, options, (err) => {
        if(err){
          subsriber.error(err);
          subsriber.complete()
        }
        subsriber.next(key);
        subsriber.complete()
      });
    });
  })
}


  public uploadTutorial(
    { folderName, file, options }: {folderName: string, file: File, options?: S3.ManagedUpload.ManagedUploadOptions}
  )
{
  return new Observable((subsriber) => {
    this.authService.checkAWSCredentials( (err) => {
      if(err){
        subsriber.error(err);
        subsriber.complete()
      }

      let bucket = new S3({ region: environment.region });

      let now = moment().format(DATETIME_FORMAT_FILE_PREFIX_S3);
      const sanitizedFileName = file.name.replace(/\s+/g, '').substring(0, 50);

      let key = `${sanitizedFileName}`;
      let bucketName = folderName ? (environment.storage.AWSS3.bucket + '/' + folderName) : environment.storage.AWSS3.bucket;

      let params = {
        Bucket: bucketName,
        Key: key, 
        Body: file,
        ContentType: file.type,
        ACL: 'bucket-owner-full-control'
      };  
      bucket.upload(params, options, (err) => {
        if(err){
          subsriber.error(err);
          subsriber.complete()
        }
        subsriber.next(key);
        subsriber.complete()
      });
    });
  })
}

  public viewImage({bucketName, key, filename }: {bucketName: string, key: string, filename?: string}, callback:(err?,data?: any) => any){
    this.authService.checkAWSCredentials( (err) => {
      if(err){
        return callback(err);
      }

      let bucket = new S3();

      let params = {
        Bucket: bucketName,
        Key: key,
      };

      bucket.getObject(params, (err, data) => {
        if(err){
          return callback(err);
        }
        //saveAs(new Blob([data.Body as any], { type: data.ContentType }), filename);
        callback(null, data);
      });
    });
  }


  public async getPresignedUrl(path: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        this.authService.checkAWSCredentials((err) => {
            if (err) {
                reject(err);
            } else {
              // temporarily hard code
                // const bucketName = "sfe-dev-data-maintenance";
                const bucketName = "sfedev-testbucket";
                const s3 = new S3();
                const params = {
                    Bucket: bucketName,
                    Key: path,
                    Expires: 3600,
                    ResponseContentDisposition: 'attachment'
                };

                s3.getSignedUrl('getObject', params, (err, url) => {
                    if (err) {
                        console.error('Error getting URL:', err);
                        reject(err);
                    } else {
                        const preSignedUrl = url;
                        resolve(preSignedUrl);
                      }
                  });
              }
          });
      });
  }

  public downloadObjectObservable(bucketname, s3Key, filename): Observable<any> {
    return this.authService.initAWSCredentialsObservable().pipe(
      switchMap(_ => this.getObjectObservable(bucketname, s3Key)),
      switchMap((data: any) => this.s3SaveAsFile(filename, data))
    );
  }

  public getObjectObservable(bucketname: string, s3Key: string): Observable<any> {
    const s3 = new S3({
      region: 'ap-southeast-1'
    });

    const params = {
      Bucket: bucketname,
      Key: s3Key,
    };

    const s3GetObjectFunction = bindNodeCallback((callback: any) => s3.getObject(params, callback));
    return s3GetObjectFunction();
  }

  public s3SaveAsFile(filename: string, s3Object: any): Observable<any> {
    const blobOption = {
      type: s3Object.ContentType
    };
    const blobParts: any[] = [
      s3Object.Body as Blob
    ];
    this.saveAsFile(filename, blobParts, blobOption);
    return of(s3Object);
  }

  public saveAsFile(filename: string, blobParts: any[], options?: BlobPropertyBag) {
    const blob = new Blob(blobParts, options);
    return saveAs(blob, filename);
  }

}