import { NgModule , APP_INITIALIZER,  isDevMode} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './/app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { PageNotFoundComponent } from './auth/page-not-found/page-not-found.component';
import { MODULES } from './global-modules';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ClickableClickModule } from 'angular-clickable-click';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChangePasswordChallengeComponent } from './auth/change-password-challenge/change-password-challenge.component';
import { FooterModule } from './pages/footer/footer.module';
import { ConfirmComponent } from './utils/confirm/confirm.component';
import { EmailInputComponent } from './utils/email-input/email-input.component';
import { ReasonInputComponent } from './utils/reason-input/reason-input.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { ScrollBottomDirective } from './directives/scroll-bottom/scroll-bottom.directive';
import { Amplify } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { ForgotPasswordGetEmailComponent } from './auth/forgot-password-get-email/forgot-password-get-email.component';
import { VerifyCodeComponent } from './auth/forgot-password-get-email/verify-code/verify-code.component';
import { ConfirmationDialogComponent } from './auth/forgot-password-get-email/confirmation-dialog/confirmation-dialog.component';
import { NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import {dbConfig} from './db-config';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';


//This is the new code
Amplify.configure({
  Auth: environment.auth,
  API: environment.api,
  Storage: environment.storage
})

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// export function unregisterServiceWorker() {
//   return () => {
//     if ('serviceWorker' in navigator) {
//       return navigator.serviceWorker.getRegistrations().then(registrations => {
//         registrations.forEach(registration => {
//           registration.unregister();
//         });
//       });
//     }
//     return Promise.resolve();
//   };
// }

// remove when approved hard coded 
  // for testing purposes only
  let firebaseConfig = {
    apiKey: "AIzaSyADFYgsD5ctWh81F25ikNLjp8KYmLYaqs0",
    authDomain: "test-6341f.firebaseapp.com",
    projectId: "test-6341f",
    storageBucket: "test-6341f.firebasestorage.app",
    messagingSenderId: "368903616625",
    appId: "1:368903616625:web:a60de68525774850c7c3d0"
  };
// for testing purposes only


// HotTable 
import { HotTableModule, HotTableRegisterer } from '@handsontable/angular';
import { registerAllModules } from 'handsontable/registry';

registerAllModules();

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireMessagingModule,
        CommonModule,
        NgxExtendedPdfViewerModule,
        BrowserModule,
        FooterModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ClickableClickModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            useDefaultLang: true,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CalendarModule.forRoot({
          provide: DateAdapter,
          useFactory: adapterFactory,
        }),
        RoundProgressModule,
        NgxIndexedDBModule.forRoot(dbConfig),
        ...MODULES,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          registrationStrategy: 'registerWhenStable:30000',
        }),
        HotTableModule
    ],
    providers: [
      // {
      //   provide: APP_INITIALIZER,
      //   useFactory: unregisterServiceWorker,
      //   multi: true,
      // },
      HotTableRegisterer
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer){
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
