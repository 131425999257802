export const environment = {
  ngswVersion: '10.0.0.27',
  production: true,
  serviceWorker: true,
  apiUrl: 'https://kd52d87sl1.execute-api.ap-southeast-1.amazonaws.com/dev/quicksight-host/get-qs-embed-url',
  showLogs: true,
  region: 'ap-southeast-1', //region
  cognitoIdentityEndpoint: 'cognito-idp.ap-southeast-1.amazonaws.com/ap-southeast-1_nI0HsILaN', //cognitoIdentityEndpoint
  appName: 'SFEWebtool',
  apiEndpoint: 'https://kd52d87sl1.execute-api.ap-southeast-1.amazonaws.com/dev',
  websocketEndpoint: "wss://p1847cgzyh.execute-api.ap-southeast-1.amazonaws.com/dev/",
  bucketName: 'sfedev-testbucket',
  auth: {
    identityPoolId: 'ap-southeast-1:0388bd03-8283-4395-90b9-45eb6b0f74c8', //CognitoIdentityPoolId
    region: 'ap-southeast-1',
    userPoolId: 'ap-southeast-1_nI0HsILaN', //cognitoUserPoolId
    userPoolWebClientId: '5q5vi03cd6ork7sm95td5pd3o8', //cognitoAppClientId
    mandatorySignIn: false,
    authenticationFlowType: 'USER_SRP_AUTH'
  },
  api: {
    endpoints: [
      {
        name: "sfe-dev",
        endpoint: "https://kd52d87sl1.execute-api.ap-southeast-1.amazonaws.com/dev" //apiEndpoint
      }
    ]
  },
  storage: {
    AWSS3: {
      bucket: 'sfedev-testbucket', //bucket
      tempBucket: 'sfedev-testbucket',
      region: 'ap-southeast-1',
      customPrefix: {
        public: ''
      }
    }
  },
  S3Creds: {
    accessID: 'AKIARQ4R54FE7CI3O7MW',
    secretKey: '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'
  },
  key:`MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArlVif0vfbpChNpIK1E1l\
    Lt+gLebS3uzdPbaCkwe5zISwQy9YWcq68IESns2aKQkPEMPOJlibFW/vj9acpeBl\
    oycCYaKVkBNpewy7on3OnNgEhOOMxXxcQQsOGJCs2UT7ri87qoxozvXOLxTCJZVc\
    rYHg8CKk5Ia+xhfNJ/s2Netu/kaRsUdSl05JrEYrOS+vhkRkXrj5h4V0BxLo3Ng9\
    zFfh44p1a8EBGEp9HaOSslri6sqtuQJwN2o0m1KuZHJytE9yjG4rMV4y2/bglLXv\
    U30MSkRy2DZbU+t3aFXbF7CroObl4adFy+P3wkHerZEN7VeKKk+YAARXX4zGXcQl\
    BwIDAQAB`,
  pvt: `MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCuVWJ/S99ukKE2\
    kgrUTWUu36At5tLe7N09toKTB7nMhLBDL1hZyrrwgRKezZopCQ8Qw84mWJsVb++P\
    1pyl4GWjJwJhopWQE2l7DLuifc6c2ASE44zFfFxBCw4YkKzZRPuuLzuqjGjO9c4v\
    FMIllVytgeDwIqTkhr7GF80n+zY1627+RpGxR1KXTkmsRis5L6+GRGReuPmHhXQH\
    Eujc2D3MV+HjinVrwQEYSn0do5KyWuLqyq25AnA3ajSbUq5kcnK0T3KMbisxXjLb\
    9uCUte9TfQxKRHLYNltT63doVdsXsKug5uXhp0XL4/fCQd6tkQ3tV4oqT5gABFdf\
    jMZdxCUHAgMBAAECggEAAwthr3hrO/qRnirZvpBlcnacTQDpcNQxHulHiXrKj1xH\
    2byDbfoA3r9aTGSpywqp9TB+MqQ8fRjZ1ai5TppHHBzLyVm2A/Ca2HfF9RX+XDXX\
    ilyQ8kf0MnyXb+1i3155iLaxc46FLAwV4DOcGlzr4+nPPFJWj9kcjsgOoKKd9T4S\
    WsX8DVLg0znMo3cdyc/hjNmC67/vNz93nLeqiVbZwE3ypVTx45U4MhMBq5YLvApM\
    8d4+c4kyZoi8T747/JXtoIweB/35gnqjTkpSJJsuzgKCuBiYvdxvXZfXyuTZCZSR\
    5wLRbGu9ar4sxIjnIyLkxUSyfsnPHOG49mV5ueJU2QKBgQD0Q7PkZEyQMhHeVnwz\
    PD98tnojSaFlEp4xB4paIlDOn2ofxjqJQzug2sHg0mULWw1kc8viOe8Ld0eCTqQS\
    bSFgpL9oI09ovsn70VgaYbIY7IVTDMYhEdGOLjy4bTAXHeCGCG0rlI85GbRpKvzk\
    gMhBWJ/rNoLyXF3tJmbRp80iaQKBgQC2tZN/tpJEqjaFq4M6xabc3hd5gLa8Hmkj\
    aw99nbMkp+hKDGd4n0UeMIPbzEIlzEdYoBwjHaFxj3R8STRt7bew/WPYusLwbIGy\
    o8/GU9Kl7sgkBda1buVdox6cXqctyrRqIowyI61Hhv7K7+t3pljBzuGAbGfm6SOZ\
    DqnJdd497wKBgDr+LvA2GReqQA6GrLBjGKIyIAVtvwD+Ih6NR6jFKOb+MU9DPNfx\
    2PDcZH670ZOQhgeAv/j/LPCD+k1KWO3qVWlV4LRx8rfFfFrJEJIujije1sRcDW3n\
    M8k2+YtBM6Ahoxw75MHlu0F7J8tOio1VXQgLM3RHUhDepgCkiaiQ8kYhAoGBAIwY\
    trza9VUJXz0nwjwyijwZX86ez393og6ybPJZFaApz4fX8w8esRTxBmpnOOFavepK\
    +shCVCSLZzpVt4oDGTLxWLo6mndX/CtKSGs+ourxKS3V/Eiqe91M0o/8VBxcrfIk\
    A3P+v0N1U7GwPdFFEBGr3GVoulLouY8HG1GKu5qpAoGBALiz0/VtBz5aFtg0Wq+d\
    9MbU3tA48z3kNCUH0dVS7bxmVSJrbiHPqS3yz2EH8v0t75XCJFX8+H/84OwiKeTF\
    stpSJMBHu5WyLOBGxpia+hNaNtUj8LOyZkuHOeuIT7ku0Nab226a5K0Q2WUWPHQJ\
    i4QxmrzvBioG7IhqhIQcb1sX`,
    aesKey: `n7skR3igrFkPdGRYsk2C/G9xzsvS//r91eM/s9wwdvw=`
};

