import { DBConfig } from 'ngx-indexed-db';

export const dbConfig: DBConfig = {
    name: 'revampDB',
    version: 65,
    objectStoresMeta: [{
        store: 'acp_settings',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'settings', keypath: 'settings', options: { unique: false } },
        ]
    },
    {
        store: 'acp_call_modes',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'call_modes', keypath: 'call_modes', options: { unique: false } },
        ]
    },    
    {
        store: 'acp_cycle_dates',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_dates', keypath: 'cycle_dates', options: { unique: false } },
        ]
    },
    {
        store: 'acp_holder',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'calls', keypath: 'calls', options: { unique: false } },
        ]
    },
    {
        store: 'acp_activities_holder',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'activity_calls', keypath: 'activity_calls', options: { unique: false } },
        ]
    },
    {
        store: 'acp_doctor_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'doctors', keypath: 'doctors', options: { unique: false } },
        ]
    },
    {
        store: 'acp_activities_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'activities', keypath: 'activities', options: { unique: false } },
        ]
    },
    {
        store: 'app_user',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'code', keypath: 'code', options: { unique: false } },
            { name: 'email_address', keypath: 'email_address', options: { unique: false } },
            { name: 'first_name', keypath: 'first_name', options: { unique: false } },
            { name: 'last_name', keypath: 'last_name', options: { unique: false } },
            { name: 'middle_name', keypath: 'middle_name', options: { unique: false } },
            { name: 'username', keypath: 'username', options: { unique: false } },
        ]
    },
    {
        store: 'web_permissions',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'permissions', keypath: 'permissions', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_incidental_list', keypath: 'acp_incidental_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_activity', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_incidental_activity_list', keypath: 'acp_incidental_activity_list', options: { unique: false } },
        ]

    },
    {
        store: 'acp_dm_list', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'dm_list', keypath: 'dm_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_product_list', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'products_lists', keypath: 'products_lists', options: { unique: false } },
        ]
    },
    {
        store:'acp_products', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'doctor_id', keypath: 'doctor_id', options: { unique: false } },
            { name: 'products', keypath: 'products', options: { unique: false } },
        ]
    },
    {
        store:'acp_products_planned', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'products', keypath: 'products', options: { unique: false } },
        ]
    },
    {
        store: 'acp_note_list', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'note_list', keypath: 'note_list', options: { unique: false } },
        ]
    },
    {
        store:'acp_notes', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'doctor_id', keypath: 'doctor_id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
        ]
    },
    {
        store:'acp_notes_planned', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
        ]
    },
    {
        store:'acp_notes_activity', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
        ]
    },
    {
        store:'acp_notes_activity_planned', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_doctor_start', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_doctor_start_list', keypath: 'incidental_doctor_start_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_doctor_start_planned', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_doctor_start_planned', keypath: 'incidental_doctor_start_planned', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_activity_start', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_activity_start_list', keypath: 'incidental_activity_start_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_activity_start_planned', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_activity_start_planned_list', keypath: 'incidental_activity_start_planned_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_material', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_material_list', keypath: 'acp_material_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_offline_send', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_offline_send_list', keypath: 'acp_offline_send_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_activity_offline_send', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_activity_offline_send_list', keypath: 'acp_activity_offline_send_list', options: { unique: false } },
        ]
    },
    {
        store:'acp_activity_details', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'details', keypath: 'details', options: { unique: false } },
        ]
    },
    {
        store: 'ngsw_version',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
          { name: 'version', keypath: 'version', options: { unique: false } },
        ]
      },
      {
        store: 'dashboard_rate_past', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'rate_past', keypath: 'rate_past', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_reach_past', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'reach_past', keypath: 'reach_past', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_concentration_past', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'concentration_past', keypath: 'concentration_past', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_cycle', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle', keypath: 'cycle', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_summary_headers', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'summary_headers', keypath: 'summary_headers', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_rate', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'rate', keypath: 'rate', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_reach', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'reach', keypath: 'reach', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_concentration', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'concentration', keypath: 'concentration', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_active', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_active', keypath: 'time_in_active', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_date', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_date', keypath: 'time_in_date', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_image', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_image', keypath: 'time_in_image', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_remarks', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_remarks', keypath: 'time_in_remarks', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_signature', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_signature', keypath: 'time_in_signature', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_new_attendance', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'new_attendance', keypath: 'new_attendance', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_out_active', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_active', keypath: 'time_in_active', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_out_date', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_date', keypath: 'time_in_date', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_out_image', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_image', keypath: 'time_in_image', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_out_remarks', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_remarks', keypath: 'time_in_remarks', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_out_signature', storeConfig: {keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_signature', keypath: 'time_in_signature', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_settings',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'settings', keypath: 'settings', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_end_start_time',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'end_start_time', keypath: 'end_start_time', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_month_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'month_list', keypath: 'month_list', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_batch_code',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'batch_code', keypath: 'batch_code', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_institution_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'institution_list', keypath: 'institution_list', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_selected_institution',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'selected_institution', keypath: 'selected_institution', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_offline_upload',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'offline_upload', keypath: 'offline_upload', options: { unique: false } },
        ]
    },
    {
        store: 'user_location',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'location', keypath: 'location', options: { unique: false } },
        ]
    },
    {
        store: 'detailing_aid_file_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'file_list', keypath: 'file_list', options: { unique: false } },
        ]
    },
    {
        store: 'detailing_aid_downloaded_file',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'downloaded_file', keypath: 'downloaded_file', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_doctor_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'doctor_list', keypath: 'doctor_list', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_activity_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'activity_list', keypath: 'activity_list', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_doctor_info',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'doctor_info', keypath: 'doctor_info', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_doctor_filters',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'doctor_filters', keypath: 'doctor_filters', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_cycle_boolean',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_boolean', keypath: 'cycle_boolean', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_cycle_one',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_one', keypath: 'cycle_one', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_cycle_two',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_two', keypath: 'cycle_two', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_active_cycle',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'active_cycle', keypath: 'active_cycle', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_cycle_numbers',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_numbers', keypath: 'cycle_numbers', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_cycle_dates',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_dates', keypath: 'cycle_dates', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_plotted_doctors',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'plotted_doctors', keypath: 'plotted_doctors', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_next_cycle_plotted_doctors',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'next_cycle_plotted_doctors', keypath: 'next_cycle_plotted_doctors', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_plotted_activities',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'plotted_activities', keypath: 'plotted_activities', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_next_cycle_plotted_activities',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'next_cycle_plotted_activities', keypath: 'next_cycle_plotted_activities', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_header_id',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'header_id', keypath: 'header_id', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_show_disapprove',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'show_disapprove', keypath: 'show_disapprove', options: { unique: false } },
        ]
    },
    {
        store: 'htmlFiles', 
        storeConfig: { keyPath: 'id', autoIncrement: true }, 
        storeSchema: [
          { name: 'zipId', keypath: 'zipId', options: { unique: false } }, 
          { name: 'path', keypath: 'path', options: { unique: false } }, 
          { name: 'content', keypath: 'content', options: { unique: false } }, 
        ]
      }

    ]
}